
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import type { ArticleModel } from '~/entities/article/model'
import { toDateFormat } from '~/shared/utils/date'

export default defineComponent({
  props: {
    article: {
      type: Object as PropType<ArticleModel>,
      required: true,
    },
    color: {
      type: String,
      default: '#fff',
    },
  },
  setup(props) {
    const preview = computed(() => props.article.preview?.path ?? '')

    return { preview }
  },
  methods: { toDateFormat },
})
