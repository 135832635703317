
import { SvoiIcon } from '@svoi-ui/components/icon'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { SvoiIcon },
  props: {
    ...SvoiIcon.props,
    title: {
      type: [String, Number],
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
})
