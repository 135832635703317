
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import type { UserProfileModel } from '~/entities/user/model'
import { Avatar } from '~/shared/ui/base'
import { previewUserName } from '~/shared/utils/helpers'

export default defineComponent({
  components: { Avatar },
  props: {
    profile: {
      type: Object as PropType<UserProfileModel>,
      required: true,
    },
  },

  setup(props) {
    const userName = computed(() => {
      const { name, surName } = props.profile

      return previewUserName(surName, name)
    })

    return { userName }
  },
})
