
import { defineComponent, ref, onMounted, watch, PropType } from '@nuxtjs/composition-api'
import { ControlKey, GeoObjectCollection, Map as IMap, MapEvent, Placemark } from 'yandex-maps'
import type { ControlsOptionsRelationInterface, MarkerMap } from '~/shared/interface'
import { ScriptLoader } from '~/shared/class'

export default defineComponent({
  name: 'VueYandexMap',
  props: {
    markersList: {
      type: Array as PropType<Array<MarkerMap>>,
      default: () => [],
    },
    activeMarkerId: {
      type: String,
      default: '',
    },
    isCloseBalloons: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const map = ref<IMap | null>(null)

    const controlsOptionsMap = ref<ControlsOptionsRelationInterface[]>([])

    const controlsList = ref<ControlKey[]>([
      'trafficControl',
      'rulerControl',
      'searchControl',
      'fullscreenControl',
      'typeSelector',
      'zoomControl',
      'geolocationControl',
    ])

    const previousActiveMarkerId = ref<string | null>(null)
    const markerPlacemarks = ref<Map<string, Placemark>>(new Map())

    const clearUnusableControls = (): void => {
      controlsList.value.forEach((control: string) => map.value!.controls.remove(control))
    }

    const addControls = (): void => {
      controlsOptionsMap.value.forEach((relation: ControlsOptionsRelationInterface) => {
        map.value!.controls.add(relation.key, relation.options)
      })
    }

    const closeAllBalloons = (): void => {
      markerPlacemarks.value.forEach((placemark: Placemark) => {
        if (placemark && placemark.balloon && placemark.balloon.isOpen()) {
          placemark.balloon.close()
        }
      })
    }

    const addMarkersToMap = (): void => {
      closeAllBalloons()

      const activeIconUrl = require('~/assets/images/yandex-map/marker-active.svg')
      const bounds: GeoObjectCollection = new ymaps.GeoObjectCollection()

      props.markersList.forEach((marker: MarkerMap) => {
        const placemark: Placemark = new ymaps.Placemark(
          [marker.latitude, marker.longitude],
          {
            balloonContentHeader: marker.balloon.name,
            balloonContentBody: marker.balloon.address,
          },
          {
            iconLayout: 'default#image',
            iconImageHref: require('~/assets/images/yandex-map/marker.svg'),
            iconImageSize: [30, 30],
            iconImageOffset: [-15, -15],
            balloonOffset: [-50, 10],
            hideIconOnBalloonOpen: false,
            balloonLayout: ymaps.templateLayoutFactory.createClass(
              '<div class="custom-balloon">' +
                '<p class="custom-balloon-header"> {{ properties.balloonContentHeader }}</p>' +
                '<p  class="custom-balloon-content">{{ properties.balloonContentBody }}</p>' +
                '</div>'
            ),
          }
        )

        placemark.events.add('click', (): void => onMarkerClick(marker))

        placemark.events.add('balloonopen', (): void => {
          emit('onOpenBalloon', marker.id)

          placemark.options.set('iconImageHref', activeIconUrl)
        })

        placemark.events.add('balloonclose', (): void => {
          placemark.options.set('iconImageHref', require('~/assets/images/yandex-map/marker.svg'))

          emit('onCloseBalloon', marker.id)
        })

        bounds.add(placemark)

        markerPlacemarks.value.set(marker.id, placemark)
      })

      map.value!.geoObjects.add(bounds)

      if (bounds.getLength() > 0) {
        const mapBounds: number[][] | null = bounds.getBounds()

        if (mapBounds) {
          map.value!.setBounds(mapBounds, { checkZoomRange: true }).then(() => {
            if (bounds.getLength() === 1) {
              map.value!.setZoom(14)
            }
          })
        }
      }
    }

    const onMarkerClick = (marker: MarkerMap): void => {
      emit('onMarkerClick', marker)
    }

    const closeBalloon = (id: string): void => {
      const marker = markerPlacemarks.value.get(id)
      if (marker) {
        marker.balloon.close()
      }
    }

    const initWatchers = () => {
      watch(
        () => props.markersList,
        () => {
          addMarkersToMap()
        },
        { deep: true }
      )
      watch(
        () => props.activeMarkerId,
        (newActiveMarker: string) => {
          if (previousActiveMarkerId.value) {
            closeBalloon(previousActiveMarkerId.value)
          }

          if (newActiveMarker) {
            const newPlacemark = markerPlacemarks.value.get(newActiveMarker)
            if (newPlacemark) {
              newPlacemark.balloon.open()
            }
            previousActiveMarkerId.value = newActiveMarker
          } else {
            previousActiveMarkerId.value = null
          }
        }
      )
    }

    const initMap = (): void => {
      ymaps.ready((): void => {
        map.value = new ymaps.Map(document.querySelector('.map-wrapper'), {
          center: [55.751574, 37.573856],
          zoom: 10,
        })

        clearUnusableControls()
        addControls()
        addMarkersToMap()
        initWatchers()

        map.value!.events.add('click', (event: MapEvent): void => {
          const target = event.get('target')
          if (!(target && target instanceof ymaps.Placemark)) {
            event.preventDefault()
          }
        })
      })
    }

    onMounted((): void => {
      ScriptLoader.attach(
        `https://api-maps.yandex.ru/2.1/?apikey=73dd7f55-c9a7-4786-83f8-5a9bdf3ca676&lang=ru_RU`
      ).then(initMap)
    })

    return { map }
  },
})
