
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiLabel } from '@svoi-ui/components/label'
import type { Event } from '~/interfaces/events'

export default defineComponent({
  name: 'MainEventsItem',
  components: { SvoiLabel },
  props: {
    eventItem: {
      type: Object as PropType<Event>,
      required: true,
    },
  },
})
