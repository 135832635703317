
import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { SvoiInput } from '@svoi-ui/components/input'
import { SvoiIcon } from '@svoi-ui/components/icon'
import type { SchoolOrganizationModel } from '~/entities/school/model'
import type { RegionModel } from '~/shared/model'

export default defineComponent({
  name: 'MapContent',
  components: { SvoiIcon, SvoiInput },
  props: {
    activeMarkerId: {
      type: String,
      default: '',
    },
    schools: {
      type: Array as PropType<Array<SchoolOrganizationModel>>,
      default: () => [],
    },
  },
  emits: ['select-region', 'select-school'],
  setup(props, { emit }) {
    const searchRegion = ref<string>('')
    const activeRegion = ref<RegionModel | null>(null)

    const regions = computed<Array<RegionModel>>(() => {
      const regionsId = new Set<string>()
      const regions = new Set<RegionModel>()

      props.schools.forEach((item) => {
        const region = item.organization.address?.region
        if (region && !regionsId.has(region.id)) {
          regions.add(region)
          regionsId.add(region.id)
        }
      })

      return Array.from(regions)
    })
    const filteredRegions = computed(() => {
      if (!searchRegion.value) {
        return regions.value
      } else {
        const lowerCaseSearch = searchRegion.value.toLowerCase()
        return regions.value.filter((region) => region.withType.toLowerCase().includes(lowerCaseSearch))
      }
    })
    const schoolByRegion = computed<Array<SchoolOrganizationModel>>(() => {
      if (activeRegion.value) {
        return props.schools.filter((item) => item.organization.address?.region?.id === activeRegion.value!.id)
      }

      return []
    })
    const regionMarkerId = computed(() => {
      if (props.activeMarkerId) {
        const school = props.schools.find((i) => i.organization.hid === props.activeMarkerId)
        return school?.organization.address?.region?.id ?? ''
      }

      return ''
    })

    const getAddress = (address: string): string => {
      const stringArray = address.split(',')

      stringArray.shift()

      return stringArray.join(',').trim()
    }

    const onClickRegion = (region: RegionModel): void => {
      activeRegion.value = region
      emit('select-region', region)
    }

    const onClickSchool = (school: SchoolOrganizationModel): void => {
      emit('select-school', school)
    }

    const onClickBackToRegion = (): void => {
      searchRegion.value = ''
      activeRegion.value = null
      emit('select-region', undefined)
    }

    return {
      regions,
      activeRegion,
      searchRegion,
      filteredRegions,
      schoolByRegion,
      regionMarkerId,
      onClickBackToRegion,
      onClickRegion,
      getAddress,
      onClickSchool,
    }
  },
})
