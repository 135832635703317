import { render, staticRenderFns } from "./ArticleNavContent.vue?vue&type=template&id=104a3f20&scoped=true&"
import script from "./ArticleNavContent.vue?vue&type=script&lang=ts&"
export * from "./ArticleNavContent.vue?vue&type=script&lang=ts&"
import style0 from "./ArticleNavContent.vue?vue&type=style&index=0&id=104a3f20&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "104a3f20",
  null
  
)

export default component.exports