
import { defineComponent, PropType, ref, useContext, watchEffect } from '@nuxtjs/composition-api'
import { SvoiButton } from '@svoi-ui/components/button'
import { SvoiInput } from '@svoi-ui/components/input'
import type { ArticleFilters } from '~/entities/article/interface'
import type { StatusModel } from '~/entities/workflow/model'
import { WorkflowSchemes } from '~/entities/workflow/enum'

export default defineComponent({
  name: 'ArticlesModerationFilters',
  components: { SvoiButton, SvoiInput },
  props: {
    initialFilters: {
      type: Object as PropType<ArticleFilters>,
      default: () => {},
    },
  },
  emits: ['on-change'],
  setup(props, { emit }) {
    const ctx = useContext()

    const filters = ref<ArticleFilters>({})
    const statuses = ref<Array<StatusModel>>([])

    const initFilters = () => {
      filters.value = {
        status: props.initialFilters?.status ?? undefined,
        search: props.initialFilters?.search ?? '',
      }
    }

    const changeFilterStatus = (value: StatusModel) => {
      if (!isActiveStatus(value)) {
        filters.value.status = value.id ? [value.id] : undefined
        emit('on-change', filters.value)
      }
    }

    const changeFilterSearch = () => {
      emit('on-change', filters.value)
    }

    const isActiveStatus = (statusValue: StatusModel): boolean => {
      if (Array.isArray(filters.value.status)) {
        return filters.value.status.includes(statusValue.id)
      } else {
        return statusValue.id === ''
      }
    }

    const getStatuses = () => {
      ctx.$services.workflow.getStatuses(WorkflowSchemes.article).then((data) => {
        statuses.value = data.filter((s) => s.alias.match(/_(waiting|review|published|unpublished|rejected)$/))
      })
    }

    watchEffect(() => initFilters())

    getStatuses()

    return {
      statuses,
      filters,
      changeFilterStatus,
      changeFilterSearch,
      isActiveStatus,
    }
  },
})
