
import { computed, defineComponent, PropType, useContext, ref, toRef } from '@nuxtjs/composition-api'
import { SvoiButton, SvoiButtonLink } from '@svoi-ui/components/button'
import { RightSidebar } from '~/shared/ui/layouts'
import { ArticleModel } from '~/entities/article/model'
import type { TransitionModel } from '~/entities/workflow/model'
import { TransitionTypeEnum } from '~/entities/workflow/enum'
import { ArticleRecommendedList, ArticleUserCard, Article } from '~/widgets/articles/view/article'
import { PermissionsEnum } from '~/shared/enums'
import { usePermissions, useErrors, useClipboard } from '~/composables'

export default defineComponent({
  components: { RightSidebar, ArticleRecommendedList, SvoiButton, ArticleUserCard, Article, SvoiButtonLink },
  props: {
    article: {
      type: Object as PropType<ArticleModel>,
      required: true,
    },
  },
  setup(props) {
    const ctx = useContext()
    const { hasPermission } = usePermissions()
    const { displayError, ignoreError } = useErrors()
    const { copyToClipboard } = useClipboard()

    const articleModel = toRef(props, 'article')
    const availableTransitions = ref<Array<TransitionModel>>([])
    const disableTransitions = ref(false)

    const isAuthor = computed(() => {
      if (ctx.$tstore.user.authorizedUser && hasPermission(PermissionsEnum.updatePublication)) {
        return ctx.$tstore.user.authorizedUser.id === props.article.author.id
      }
      return false
    })
    const hasModeration = computed(() => hasPermission(PermissionsEnum.updateAnyPublication))
    const hasEditOrModeration = computed(() => isAuthor.value || hasModeration.value)
    const isPublished = computed(() => ArticleModel.checkPublished(props.article))
    const recommendedArticles = computed(() => props.article.articles.filter((a) => ArticleModel.checkPublished(a)))
    const editLink = computed(() => {
      if (!hasEditOrModeration.value) {
        return undefined
      }
      const param = hasModeration.value ? 'articles-moderation' : 'articles'
      return `/account/${param}/edit/${props.article.id}`
    })

    const onClickCopyLink = () => {
      copyToClipboard(`${ctx.$config.host}/publications/${props.article.slug}`)
    }

    const getAvailableTransitions = () => {
      if (hasEditOrModeration.value) {
        ignoreError(
          ctx.$services.workflow.getAvailableTransitions(TransitionTypeEnum.article, props.article.id).then((data) => {
            availableTransitions.value = data
          })
        )
      }
    }

    const applyTransition = (transition: TransitionModel) => {
      disableTransitions.value = true
      displayError(
        ctx.$services.workflow
          .applyTransitions(props.article.id, transition.id, TransitionTypeEnum.article)
          .then((status) => {
            articleModel.value.status = status
            availableTransitions.value = []
            getAvailableTransitions()
          })
      ).finally(() => {
        disableTransitions.value = false
      })
    }

    getAvailableTransitions()

    return {
      editLink,
      isPublished,
      availableTransitions,
      disableTransitions,
      recommendedArticles,
      onClickCopyLink,
      applyTransition,
    }
  },
})
