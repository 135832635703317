
import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import Multiselect from 'vue-multiselect'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiHint } from '@svoi-ui/components/hint'
import { uuid } from '@svoi-ui/shared/utils/helpers'
import { OptionSelect, OptionSelectKey, OptionsSelect, SelectSize, SelectSizeEnum } from '@svoi-ui/interfaces/select'
import { isString, isUnknownObject } from '@svoi-ui/shared/utils/guards'

export default defineComponent({
  name: 'SvoiSelect',
  components: { Multiselect, SvoiIcon, SvoiHint },
  inheritAttrs: false,
  props: {
    value: {
      type: [Array, Object] as PropType<OptionsSelect>,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array as PropType<Array<OptionSelect>>,
      default: () => []
    },
    // Label from option Object, that will be visible in the dropdown.
    itemLabel: {
      type: String,
      default: 'title'
    },
    // Key to compare objects
    trackBy: {
      type: [String, Number, Symbol] as PropType<OptionSelectKey>,
      default: 'id'
    },
    internalSearch: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    size: {
      type: String as PropType<SelectSize>,
      default: SelectSizeEnum.l,
      validator: (value: unknown) => {
        return isString(value) && [SelectSizeEnum.m.toString(), SelectSizeEnum.l.toString()].includes(value)
      }
    },
    tabindex: {
      type: Number,
      default: 0
    },
    clearSearch: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'blur', 'search-change'],
  setup(props, { emit }) {
    const isOpenDropdown = ref(false)
    const id = uuid()

    const toggleDropdown = (isOpen: boolean) => {
      isOpenDropdown.value = isOpen
    }

    const isSelectedValue = computed(() => {
      if (Array.isArray(props.value)) {
        return props.value.length
      }

      return !!props.value
    })

    const activatorClass = computed(() => ({
      '-active': isOpenDropdown.value
    }))

    const labelClass = computed(() => ({
      '-error': !!props.error,
      '-active': isOpenDropdown.value || isSelectedValue.value,
      '-opened-dropdown': isOpenDropdown.value
    }))

    const multiselectClass = computed(() => ({
      '-disabled': props.disabled,
      '-error': !!props.error,
      '-search-center': !props.label
    }))

    const componentClass = computed(() => ({
      [`-size-${props.size}`]: true
    }))

    const getOptionIcon = (option: OptionSelect) => {
      const isMultipleSelectedOption = Array.isArray(props.value) && props.value.includes(option)
      const isSingleSelectedOption =
        isUnknownObject(props.value) && props.value[props.trackBy] === option[props.trackBy]

      if (isMultipleSelectedOption || isSingleSelectedOption) {
        return { icon: 'essential/tick', class: '-checkmark-icon' }
      }

      return { icon: option.icon }
    }

    const onBlur = () => {
      emit('blur')
    }

    const onSelect = (options: OptionsSelect) => {
      emit('input', options)
    }

    const onRemove = (targetOption: OptionSelect) => {
      const updatedValue = Array.isArray(props.value)
        ? props.value.filter(option => option[props.trackBy] !== targetOption[props.trackBy])
        : null

      onSelect(updatedValue)
    }

    const onSearchChange = (value: string) => {
      emit('search-change', value)
    }

    return {
      id,
      labelClass,
      multiselectClass,
      activatorClass,
      componentClass,
      toggleDropdown,
      getOptionIcon,
      onRemove,
      onSelect,
      onBlur,
      onSearchChange
    }
  }
})
