
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiButton } from '@svoi-ui/components/button'
import { BannerRegister } from '~/interfaces/banner'

export default defineComponent({
  name: 'BannerRegister',
  components: { SvoiButton },
  props: {
    data: {
      type: Object as PropType<BannerRegister>,
      required: true,
    },
  },
  setup(props) {
    const styles = computed(() => ({
      backgroundColor: props.data.background,
    }))

    return { styles }
  },
})
