
import { defineComponent, PropType, useContext, ref, computed, onMounted } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiSwiper } from '@svoi-ui/components/swiper'
import { ArticleCard } from '~/widgets/articles'
import type { ArticleModel } from '~/entities/article/model'
import { useErrors, useDevices } from '~/composables'

export default defineComponent({
  components: { SvoiSwiper, SvoiIcon, ArticleCard },
  props: {
    article: {
      type: Object as PropType<ArticleModel>,
      required: true,
    },
  },
  setup(props) {
    const ctx = useContext()
    const { ignoreError } = useErrors()
    const { isMobile } = useDevices()

    const responsive = [
      { countVisible: 1, minWidth: 0, maxWidth: 530, gap: 16 },
      { countVisible: 2, minWidth: 531, maxWidth: 768, gap: 10 },
    ]

    const prevArticle = ref<ArticleModel>()
    const nextArticle = ref<ArticleModel>()

    const prevLink = computed(() => (prevArticle.value ? `/publications/${prevArticle.value.slug}` : ''))
    const nextLink = computed(() => (nextArticle.value ? `/publications/${nextArticle.value.slug}` : ''))
    const articles = computed(() => {
      const result = []
      if (prevArticle.value) {
        result.push(prevArticle.value)
      }
      if (nextArticle.value) {
        result.push(nextArticle.value)
      }
      return result
    })

    const getPrevArticle = () => {
      ignoreError(
        ctx.$services.article.getPreviousArticle(props.article.id).then((data) => {
          prevArticle.value = data
        })
      )
    }

    const getNextArticle = () => {
      ignoreError(
        ctx.$services.article.getNextArticle(props.article.id).then((data) => {
          nextArticle.value = data
        })
      )
    }

    onMounted(() => {
      getPrevArticle()
      getNextArticle()
    })

    return { prevArticle, nextArticle, prevLink, nextLink, isMobile, responsive, articles }
  },
})
