import { render, staticRenderFns } from "./MainEventsItem.vue?vue&type=template&id=463acab8&scoped=true&"
import script from "./MainEventsItem.vue?vue&type=script&lang=ts&"
export * from "./MainEventsItem.vue?vue&type=script&lang=ts&"
import style0 from "./MainEventsItem.vue?vue&type=style&index=0&id=463acab8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "463acab8",
  null
  
)

export default component.exports