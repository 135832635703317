
import { computed, defineComponent, PropType, ref, UnwrapRef } from '@nuxtjs/composition-api'
import VueScrollTo from 'vue-scrollto'
import { SvoiIcon } from '@svoi-ui/components/icon'
import type { ArticleModel } from '~/entities/article/model'
import { isHeadingLevel, isNonEmptyArray, isString, isUIUniqid, isUnknownObject } from '~/shared/utils/guards'
import type { JSONContent } from '~/interfaces/editor'
import type { HeadingLevel, UIUniqId } from '~/shared/types'
import { headingExtensionName, prefixHeadingExtensionId } from '~/shared/const'

export default defineComponent({
  components: { SvoiIcon },
  props: {
    article: {
      type: Object as PropType<ArticleModel>,
      required: true,
    },
  },

  setup(props) {
    const isShow = ref<boolean>(false)
    const comparedLevels: Array<HeadingLevel> = [2, 3]
    const minItemsCount = 3

    const navContent = computed(() => {
      const result: Array<{ uid: UIUniqId; text: string }> = []

      const content = props.article.content?.content

      if (!content) {
        return []
      }

      const isHeading = (node: JSONContent) => {
        return (
          node.type === headingExtensionName &&
          isUnknownObject(node.attrs) &&
          isHeadingLevel(node.attrs.level) &&
          comparedLevels.includes(node.attrs.level)
        )
      }

      const getTextContent = (content: JSONContent[] | undefined): string | null => {
        if (content && isNonEmptyArray(content)) {
          return content.find((c) => c.type === 'text' && isString(c.text))?.text || null
        }

        return null
      }

      content.forEach((node) => {
        if (isHeading(node) && isUnknownObject(node.attrs) && isUIUniqid(node.attrs.uid)) {
          const text = getTextContent(node.content)
          const uid = node.attrs.uid

          if (text && uid) {
            result.push({ uid, text })
          }
        }
      })

      return result
    })

    const items = computed(() => (isShow.value ? navContent.value : navContent.value.slice(0, minItemsCount)))

    const activatorClass = computed(() => ({
      '-active': isShow.value,
    }))

    const scrollTo = (item: UnwrapRef<typeof navContent>[number]) => {
      VueScrollTo.scrollTo(`[${prefixHeadingExtensionId}=${item.uid}]`)
    }

    return { isShow, minItemsCount, items, navContent, activatorClass, scrollTo }
  },
})
