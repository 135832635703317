
import { defineComponent, PropType, ref, useContext, watchEffect } from '@nuxtjs/composition-api'
import type { ArticleFilters } from '~/entities/article/interface'
import type { CategoryModel } from '~/entities/category/model'
import { SearchInput } from '~/shared/ui/base'
import { useGlobalState } from '~/composables'
import { PUBLICATIONS_ROUTE } from '~/shared/const'

export default defineComponent({
  components: { SearchInput },
  props: {
    initialFilters: {
      type: Object as PropType<ArticleFilters>,
      default: () => {},
    },
  },
  emits: ['on-change', 'on-create-click'],
  setup(props, { emit }) {
    const ctx = useContext()
    const { breadcrumbsState } = useGlobalState()

    const selectedCategory = ref<CategoryModel>()
    const categories = ref<Array<CategoryModel>>([])
    const filters = ref<ArticleFilters>({})

    const initFilters = () => {
      filters.value = {
        search: props.initialFilters?.search ?? '',
        category: props.initialFilters?.category ?? undefined,
      }

      updateSelectedCategory()
    }

    const changeFilterSearch = () => {
      emit('on-change', filters.value)
    }

    const changeFilterCategory = (value: CategoryModel) => {
      filters.value.category = filters.value.category === value.slug ? undefined : value.slug
      emit('on-change', filters.value)
    }

    const updateSelectedCategory = () => {
      if (filters.value.category) {
        const selected = categories.value.find((i) => i.slug === filters.value.category)
        if (selected) {
          selectedCategory.value = selected
          breadcrumbsState.update('category', selected.title, `${PUBLICATIONS_ROUTE}/search?category=${selected.slug}`)
          return
        }
      }

      selectedCategory.value = undefined
      breadcrumbsState.update('category', '')
    }

    const getCategories = () => {
      ctx.$services.category.getCategories().then((data) => {
        categories.value = data
        updateSelectedCategory()
      })
    }

    watchEffect(() => initFilters())

    getCategories()

    return {
      filters,
      selectedCategory,
      categories,
      changeFilterSearch,
      changeFilterCategory,
    }
  },
})
