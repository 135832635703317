
import { computed, defineComponent, PropType, ref, onMounted, useContext } from '@nuxtjs/composition-api'
import { isUnknownObject } from '~/shared/utils/guards'
import { ScriptLoader, DeviceDetect } from '~/shared/class'
import { getUIUniqId } from '~/shared/utils/helpers'
import type { ShareData } from '~/interfaces/share'

export default defineComponent({
  name: 'YandexShare',
  props: {
    data: {
      type: Object as PropType<ShareData>,
      required: true,
    },
    services: {
      type: Array as PropType<Array<'vkontakte' | 'telegram'>>,
      default: () => ['vkontakte', 'telegram'],
    },
    short: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const ctx = useContext()

    const id = ref('')

    const dataServices = computed(() => {
      return props.services.join(',')
    })

    const preparedDescription = computed(() => {
      return props.data.description.length > 100 ? `${props.data.description.slice(0, 100)}...` : props.data.description
    })

    onMounted(() => {
      id.value = getUIUniqId()
      ScriptLoader.attach('https://yastatic.net/share2/share.js').then(() => initShare())
    })

    const initShare = () => {
      if (isUnknownObject(window) && isUnknownObject(window.Ya) && typeof window.Ya.share2 === 'function') {
        const userAgent = DeviceDetect.parse(navigator.userAgent)
        const isMobile = userAgent.device.type === 'mobile' || userAgent.device.type === 'tablet'

        window.Ya.share2(id.value, {
          content: {
            url: getFullUrl(props.data.url),
            title: isMobile ? encodeURIComponent(props.data.title) : props.data.title,
            description: isMobile ? encodeURIComponent(preparedDescription.value) : preparedDescription.value,
            image: getFullUrl(props.data.image),
          },
          theme: {
            services: dataServices.value,
            shape: 'round',
            bare: false,
            popupPosition: 'outer',
            size: 'l',
          },
        })
      }
    }

    const getFullUrl = (path: string) => `${ctx.$config.host}${path}`

    return { id }
  },
})
