
import { defineComponent } from '@nuxtjs/composition-api'
import { AgroclassesinfoCard } from './'

export default defineComponent({
  name: 'AgroclassInfo',
  components: { AgroclassesinfoCard },

  setup() {
    const row1 = [
      {
        id: 1,
        title: 'Готовые уроки',
        description:
          'Конспекты для учеников и учителя, проверочные тесты. Ученики могут заниматься дистанционно и закреплять изученное в классе.',
        backgroundIcon: require('~/assets/images/agroclasses-info/bg-1.svg'),
        icon: require('~/assets/images/agroclasses-info/icon-1.svg'),
      },
      {
        id: 2,
        title: 'Материалы для занятий',
        description: 'Презентации, видеоролики, инструкции для проведения полевых опытов и экспериментов.',
        backgroundIcon: require('~/assets/images/agroclasses-info/bg-2.svg'),
        icon: require('~/assets/images/agroclasses-info/icon-2.svg'),
      },
    ]

    const row2 = [
      {
        id: 1,
        title: 'Учебные модули',
        description:
          'По агрохимии, фитопатологии, технологиях выращивания культурных растений и другим агродисциплинам.',
        backgroundIcon: require('~/assets/images/agroclasses-info/bg-3.svg'),
        icon: require('~/assets/images/agroclasses-info/icon-3.svg'),
      },
      {
        id: 2,
        title: 'Классный журнал',
        description:
          'Онлайн-издание с научно-популярными статьями о современных агротехнологиях и перспективных профессиях.',
        backgroundIcon: require('~/assets/images/agroclasses-info/bg-4.svg'),
        icon: require('~/assets/images/agroclasses-info/icon-4.svg'),
      },
    ]

    const row3 = [
      {
        id: 1,
        title: 'Новости агроклассников',
        description: 'События агроклассов по всей стране: от сбора урожая до экскурсии на предприятие АПК.',
        backgroundIcon: require('~/assets/images/agroclasses-info/bg-5.svg'),
        icon: require('~/assets/images/agroclasses-info/icon-5.svg'),
      },
      {
        id: 2,
        title: 'Конкурсы и олимпиады',
        description: 'Афиша тематических мероприятий для агроклассников: от АгроНТРИ до Малой Тимирязевки',
        backgroundIcon: require('~/assets/images/agroclasses-info/bg-6.svg'),
        icon: require('~/assets/images/agroclasses-info/icon-6.svg'),
      },
      {
        id: 3,
        title: 'Материалы для учителя',
        description: 'Нормативно-правовые документы для агроклассов.',
        backgroundIcon: require('~/assets/images/agroclasses-info/bg-7.svg'),
        icon: require('~/assets/images/agroclasses-info/icon-7.svg'),
      },
    ]

    return { row1, row2, row3 }
  },
})
