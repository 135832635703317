
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiButtonLink } from '@svoi-ui/components/button'
import type { ArticleModel } from '~/entities/article/model'
import { toDateTimeFormat } from '~/shared/utils/date'
import { NEWS_ROUTE, PUBLICATIONS_ROUTE } from '~/shared/const'

export default defineComponent({
  name: 'MainNews',
  components: { SvoiButtonLink },
  props: {
    articles: {
      type: Array as PropType<Array<ArticleModel>>,
      default: () => [],
    },
  },
  setup() {
    return { NEWS_ROUTE, PUBLICATIONS_ROUTE }
  },
  methods: { toDateTimeFormat },
})
