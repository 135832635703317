
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { ArticleChip } from '~/widgets/articles/view/article'
import type { ArticleModel } from '~/entities/article/model'
import { toDateFormat } from '~/shared/utils/date'

export default defineComponent({
  components: { ArticleChip },

  props: {
    articles: {
      type: Array as PropType<Array<ArticleModel>>,
      required: true,
    },
  },

  setup() {
    return { toDateFormat }
  },
})
