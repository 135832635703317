
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import type { CommentModel } from '~/entities/comments/model'
import { toDateTimeFormat } from '~/shared/utils/date'

export default defineComponent({
  components: { SvoiIcon },
  props: {
    comment: {
      type: Object as PropType<CommentModel>,
      required: true,
    },
  },
  setup(props) {
    const author = computed(() => {
      if (!props.comment.author) {
        return ''
      }
      return `${props.comment.author.profile?.name ?? ''} ${props.comment.author.profile?.surName ?? ''}`
    })

    return { author }
  },
  methods: { toDateTimeFormat },
})
