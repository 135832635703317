
import { defineComponent, PropType, ref, useContext, watchEffect } from '@nuxtjs/composition-api'
import { SvoiButton } from '@svoi-ui/components/button'
import { SvoiInput } from '@svoi-ui/components/input'
import { SvoiSelect } from '@svoi-ui/components/select'
import type { ArticleFilters } from '~/entities/article/interface'
import type { CategoryModel } from '~/entities/category/model'
import { StatusModel } from '~/entities/workflow/model'
import { usePermissions } from '~/composables'
import { PermissionsEnum } from '~/shared/enums'
import { WorkflowSchemes } from '~/entities/workflow/enum'

export default defineComponent({
  components: { SvoiButton, SvoiInput, SvoiSelect },
  props: {
    initialFilters: {
      type: Object as PropType<ArticleFilters>,
      default: () => {},
    },
  },
  emits: ['on-change', 'on-create-click'],
  setup(props, { emit }) {
    const ctx = useContext()
    const { hasPermission } = usePermissions()

    const permissions = {
      create: hasPermission(PermissionsEnum.createPublication),
    }

    const selectedCategory = ref<CategoryModel>()
    const categories = ref<Array<CategoryModel>>([])
    const statuses = ref<Array<StatusModel>>([])

    const filters = ref<ArticleFilters>({})

    const initFilters = () => {
      filters.value = {
        status: props.initialFilters?.status ?? undefined,
        search: props.initialFilters?.search ?? '',
        category: props.initialFilters?.category ?? undefined,
      }

      updateSelectedCategory()
    }

    const changeFilterStatus = (value: StatusModel) => {
      if (!isActiveStatus(value)) {
        filters.value.status = value.id ? [value.id] : undefined
        emit('on-change', filters.value)
      }
    }

    const changeFilterSearch = () => {
      emit('on-change', filters.value)
    }

    const changeFilterCategory = (value: CategoryModel | null) => {
      filters.value.category = value ? value.id : undefined
      emit('on-change', filters.value)
    }

    const isActiveStatus = (statusValue: StatusModel): boolean => {
      if (Array.isArray(filters.value.status)) {
        return filters.value.status.includes(statusValue.id)
      } else {
        return statusValue.id === ''
      }
    }

    const updateSelectedCategory = () => {
      if (filters.value.category) {
        const selected = categories.value.find((i) => i.id === filters.value.category)
        if (selected) {
          selectedCategory.value = selected
          return
        }
      }

      selectedCategory.value = undefined
    }

    const getCategories = () => {
      ctx.$services.category.getCategories().then((data) => {
        categories.value = data
        updateSelectedCategory()
      })
    }

    const getStatuses = () => {
      ctx.$services.workflow.getStatuses(WorkflowSchemes.article).then((data) => {
        statuses.value = [
          new StatusModel({ id: '', name: 'Все' }),
          ...data.filter((s) => !s.alias.endsWith('_deleted')),
        ]
      })
    }

    watchEffect(() => initFilters())

    getCategories()
    getStatuses()

    return {
      statuses,
      filters,
      selectedCategory,
      categories,
      permissions,
      changeFilterStatus,
      changeFilterSearch,
      changeFilterCategory,
      isActiveStatus,
    }
  },
})
