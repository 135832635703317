
import { computed, defineComponent, PropType, ref, useContext, watchEffect } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiInput } from '@svoi-ui/components/input'
import { Comment } from '.'
import { useErrors, usePermissions } from '~/composables'
import { PermissionsEnum } from '~/shared/enums'
import type { Commentable } from '~/entities/comments/interface'
import type { CommentModel } from '~/entities/comments/model'

export default defineComponent({
  components: { SvoiIcon, SvoiInput, Comment },
  props: {
    entity: {
      type: Object as PropType<Commentable>,
      required: true,
    },
  },
  setup(props) {
    const ctx = useContext()
    const { hasPermission } = usePermissions()
    const { displayError } = useErrors()

    const hasPermissionComment = hasPermission(PermissionsEnum.commentEntity)
    const comment = ref('')
    const comments = ref<Array<CommentModel>>([])
    const sortedComments = computed(() => {
      return [...comments.value].sort((a, b) => b.id - a.id)
    })

    const sendComment = () => {
      if (comment.value) {
        displayError(
          ctx.$services.comments.addComment(props.entity, comment.value).then((data) => {
            comments.value = data
            comment.value = ''
          })
        )
      }
    }

    watchEffect(() => (comments.value = props.entity.comments.data))

    return { comment, sortedComments, hasPermissionComment, sendComment }
  },
})
