
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import type { ArticleModel } from '~/entities/article/model'
import { ArticleCard } from '~/widgets/articles'
import { PUBLICATIONS_ROUTE } from '~/shared/const'

export default defineComponent({
  components: { ArticleCard },
  props: {
    list: {
      type: Array as PropType<Array<ArticleModel>>,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    columns: {
      type: Number,
      default: 3,
      validator: (value: unknown) => typeof value === 'number' && (value === 3 || value === 4),
    },
    filled: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const classes = computed(() => ({
      'three-columns': props.columns === 3,
      'four-columns': props.columns === 4,
    }))

    const isFilled = (idx: number) => {
      return props.filled && (idx === 0 || idx + 1 === props.list.length)
    }

    return { classes, PUBLICATIONS_ROUTE, isFilled }
  },
})
