
import { computed, defineComponent, PropType, toRef, useContext } from '@nuxtjs/composition-api'
import { ArticleInfo, ArticleNavContent, ArticlePrevNext, ArticleShared, ArticleSimilarLessons } from '.'
import { Comments } from '~/widgets/comments'
import type { ArticleModel } from '~/entities/article/model'
import { Editor } from '~/widgets/editor'
import { Tag } from '~/shared/ui/base'
import { PermissionsEnum } from '~/shared/enums'
import { usePermissions } from '~/composables'

export default defineComponent({
  components: {
    Comments,
    ArticleNavContent,
    ArticleSimilarLessons,
    ArticleShared,
    ArticleInfo,
    ArticlePrevNext,
    Tag,
    Editor,
  },

  props: {
    article: {
      type: Object as PropType<ArticleModel>,
      required: true,
    },
  },

  setup(props) {
    const ctx = useContext()
    const { hasPermission } = usePermissions()

    const articleModel = toRef(props, 'article')

    const updateRating = (rating: ArticleModel['rating']) => {
      articleModel.value.rating = rating
    }

    const hasModeration = computed(() => hasPermission(PermissionsEnum.updateAnyPublication))

    const isAuthor = computed(() => {
      if (ctx.$tstore.user.authorizedUser && hasPermission(PermissionsEnum.updatePublication)) {
        return ctx.$tstore.user.authorizedUser.id === props.article.author.id
      }

      return false
    })

    const hasEditOrModeration = computed(() => isAuthor.value || hasModeration.value)

    return { hasEditOrModeration, articleModel, updateRating }
  },
})
