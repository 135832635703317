
import { computed, defineComponent, ref, useContext, useFetch } from '@nuxtjs/composition-api'
import { SvoiButtonLink } from '@svoi-ui/components/button'
import { AgroclassesInfo } from '~/widgets/agroclasses-info'
import { MainBanner } from '~/widgets/banners/main-banner'
import { MainEvents } from '~/widgets/events/main-events'
import { MainNews } from '~/widgets/articles/news'
import { BannerRegisterList } from '~/widgets/banners/register'
import { MapContainer } from '~/widgets/yandex'
import { ArticlesList } from '~/widgets/articles/main-page'
import type { ArticlesMainPageData, ArticleListParams } from '~/entities/article/interface'
import type { ArticleModel } from '~/entities/article/model'
import { PUBLICATIONS_ROUTE } from '~/shared/const'

export default defineComponent({
  components: {
    MapContainer,
    MainEvents,
    MainNews,
    AgroclassesInfo,
    BannerRegisterList,
    MainBanner,
    ArticlesList,
    SvoiButtonLink,
  },
  setup() {
    const ctx = useContext()
    const news = ref<Array<ArticleModel>>([])
    const pageData = ref<ArticlesMainPageData>({ second: [], interesting: [], fresh: [], news: [] })

    const articles = computed<Array<ArticleModel>>({
      get: () => {
        const list = []
        if (pageData.value.top) {
          list.push(pageData.value.top)
        }
        list.push(...pageData.value.second)
        list.push(...pageData.value.fresh.slice(0, 6 - list.length))

        return list
      },
      set: () => null,
    })

    const getNews = (): Promise<void> => {
      const params: ArticleListParams = {
        filter: { category: 'news' },
        first: 20,
        sort: [{ column: 'PUBLISHED_AT', order: 'DESC' }],
      }
      return ctx.$services.article.getArticles('published', params).then((data) => {
        news.value = data.models
      })
    }

    const getArticles = () => {
      return ctx.$services.article.getArticlesMainPage().then((data) => {
        pageData.value = data
      })
    }

    useFetch(async () => await getNews())
    useFetch(async () => await getArticles())

    return { news, articles, pageData, PUBLICATIONS_ROUTE }
  },
})
