
import { defineComponent, PropType, useRouter, useContext } from '@nuxtjs/composition-api'
import { SvoiSwiper } from '@svoi-ui/components/swiper'
import { LessonCard } from '~/widgets/lessons'
import type { LessonModel } from '~/entities/lesson/model'
import { useDevices, useAuthorization } from '~/composables'

export default defineComponent({
  components: { SvoiSwiper, LessonCard },
  props: {
    lessons: {
      type: Array as PropType<Array<LessonModel>>,
      required: true,
    },
  },
  setup() {
    const ctx = useContext()
    const router = useRouter()
    const { isMobile } = useDevices()
    const { showAuthorizationRedirect } = useAuthorization()

    const responsive = [
      { countVisible: 1, minWidth: 0, maxWidth: 530, gap: 10 },
      { countVisible: 2, minWidth: 531, maxWidth: 768, gap: 10 },
    ]

    const onLessonClick = (lesson: LessonModel) => {
      if (ctx.$tstore.user.authorizedUser) {
        router.push(`/account/knowledge-base/lesson/view/${lesson.id}`)
      } else {
        showAuthorizationRedirect()
      }
    }

    return { isMobile, responsive, onLessonClick }
  },
})
