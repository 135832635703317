
import { defineComponent } from '@nuxtjs/composition-api'
import type { SwiperItemProp } from '@svoi-ui/interfaces/swiper'
import { SvoiSwiperBase } from '.'

export default defineComponent({
  name: 'SvoiSwiper',
  components: { SvoiSwiperBase },

  props: {
    ...SvoiSwiperBase.props,

    showIndicators: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const getOffsetControls = <T extends SwiperItemProp>(arr: Array<T>, countVisible: number): Array<T> => {
      if (!Array.isArray(arr)) {
        return []
      }

      const to = arr.length + 1 - countVisible

      return to > 0 ? arr.slice(0, to) : []
    }

    return { getOffsetControls }
  }
})
