
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiButtonLink } from '@svoi-ui/components/button'
import { ProfileNewsItem } from '.'
import type { ArticleModel } from '~/entities/article/model'
import { loopGenerator } from '~/shared/utils/helpers'
import { NEWS_ROUTE } from '~/shared/const'

export default defineComponent({
  components: { SvoiButtonLink, ProfileNewsItem },
  props: {
    articles: {
      type: Array as PropType<Array<ArticleModel>>,
      default: () => [],
    },
  },
  setup() {
    const colorGenerator = loopGenerator(['#688ED7', '#FFE37E', '#FDA6BF', '#6EBE71'])

    return { colorGenerator, NEWS_ROUTE }
  },
})
