
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import type { ArticleModel } from '~/entities/article/model'
import { toDateFormat } from '~/shared/utils/date'
import { formatNumber, previewUserName } from '~/shared/utils/helpers'

export default defineComponent({
  components: { SvoiIcon },
  props: {
    article: {
      type: Object as PropType<ArticleModel>,
      required: true,
    },
    themeType: {
      type: String as PropType<'default' | 'filled'>,
      default: 'default',
      validator: (value: string) => {
        return ['default', 'filled'].includes(value)
      },
    },
  },
  setup(props) {
    const previewSrc = computed(() => {
      if (props.article.preview) {
        return props.article.preview.path
      }

      return ''
    })

    const authorName = computed(() => {
      const { name, surName } = props.article.author.profile

      return previewUserName(surName, name)
    })

    const filledTheme = computed(() => ({
      container: { class: props.themeType === 'filled' ? 'article-card-filled' : '' },
      image: {
        style: { backgroundImage: `url(${previewSrc.value})` },
        class: [props.themeType === 'filled' ? 'preview-filled' : ''],
      },
    }))

    return { previewSrc, authorName, toDateFormat, formatNumber, filledTheme }
  },
})
