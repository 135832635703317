
import { computed, defineComponent, PropType, useContext, ref, useFetch } from '@nuxtjs/composition-api'
import { SvoiButton } from '@svoi-ui/components/button'
import { Share } from '~/widgets/yandex'
import { Chip } from '~/shared/ui/base'
import { ArticleModel } from '~/entities/article/model'
import { RatingEntityEnum, RatingTypeEnum } from '~/entities/rating/enums'
import { useAuthorization, useErrors, useFeedback, usePermissions } from '~/composables'
import { PermissionsEnum } from '~/shared/enums'
import { PUBLICATIONS_ROUTE } from '~/shared/const'
import { FeedbackTypeEnum } from '~/entities/feedback/enums'
import { ThemeModel } from '~/entities/feedback/model'

export default defineComponent({
  components: { Chip, SvoiButton, Share },
  props: {
    article: {
      type: Object as PropType<ArticleModel>,
      required: true,
    },
  },
  emits: ['update-rating'],
  setup(props, { emit }) {
    const ctx = useContext()
    const { displayError } = useErrors()
    const { hasPermission } = usePermissions()
    const { showAuthorizationRedirect } = useAuthorization()
    const { openFeedbackModal } = useFeedback()

    const hasChangeRate = hasPermission(PermissionsEnum.rateEntity)
    const feedbackThemes = ref<Array<ThemeModel>>([])

    const isLiked = computed(() => props.article.rating.userRate === RatingTypeEnum.like)
    const likes = computed(() => props.article.rating.counters.like + 2)
    const isPublished = computed(() => ArticleModel.checkPublished(props.article))
    const themePublication = computed(() => feedbackThemes.value.find((i) => ThemeModel.isTextError(i)))

    const shareData = computed({
      get: () => ({
        url: `${PUBLICATIONS_ROUTE}/${props.article.slug}`,
        title: props.article.title,
        description: props.article.description,
        image: props.article.preview?.path,
      }),
      set: () => null,
    })

    const chipRatingClasses = computed({
      get: () => ({
        chip: true,
        '-clickable': true,
        '-active': isLiked.value,
      }),
      set: () => null,
    })

    const onClickLike = () => {
      if (hasChangeRate) {
        const promise = isLiked.value
          ? ctx.$services.rating.unRate(RatingEntityEnum.article, props.article.id)
          : ctx.$services.rating.rate(RatingEntityEnum.article, props.article.id, RatingTypeEnum.like)

        displayError(promise.then((data) => emit('update-rating', data)))
      } else {
        showAuthorizationRedirect()
      }
    }

    const getFeedbackThemes = () => {
      return ctx.$services.reference.getFeedbackThemes().then((data) => {
        feedbackThemes.value = data
      })
    }

    const sendFeedback = () => {
      openFeedbackModal({ theme: themePublication.value, type: FeedbackTypeEnum.publication, id: props.article.id })
    }

    useFetch(() => getFeedbackThemes())

    return { shareData, isPublished, likes, chipRatingClasses, feedbackThemes, onClickLike, sendFeedback }
  },
})
