
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref, watch } from '@nuxtjs/composition-api'
import type { SwiperBreakpoint, SwiperItemProp, SwiperResponsiveProp } from '@svoi-ui/interfaces/swiper'
import { uuid } from '@svoi-ui/shared/utils/helpers'

export default defineComponent({
  name: 'SvoiSwiperBase',
  props: {
    items: {
      type: Array as PropType<Array<SwiperItemProp>>,
      required: true
    },
    countVisible: {
      type: Number,
      default: 1
    },
    responsive: {
      type: Array as PropType<SwiperResponsiveProp>,
      default: () => []
    },
    speedSwipe: {
      type: Number,
      default: 4
    }
  },
  emits: ['input', 'focus', 'blur'],
  setup(props) {
    const defaultBreakpoint: SwiperBreakpoint = {
      gap: 20,
      minWidth: 0,
      maxWidth: undefined,
      countVisible: props.countVisible
    }
    const possibleOffset = computed(() => props.items.length - currentBreakpointVars.value.countVisible)

    const currentDeviceWidth = ref(0)
    const swipeOffset = ref<number>(0)

    const requestFrame = (fps: number = 120) => {
      const interval = 1000 / fps
      let then: number = performance.now()
      let isCancel: boolean = false

      function animation(frameCallback: () => void, time: number = performance.now()) {
        if (isCancel) {
          return
        }

        requestAnimationFrame(now => animation(frameCallback, now))

        const delta = time - then

        if (delta > interval) {
          then = time - (delta % interval)

          frameCallback()
        }
      }

      const cancelAnimation = () => {
        isCancel = true
      }

      const runAnimation = (frameCallback: () => void) => {
        isCancel = false

        animation(frameCallback)
      }

      return { cancelAnimation, runAnimation }
    }

    const { cancelAnimation, runAnimation } = requestFrame()

    const sortedItems = computed(() =>
      props.items
        .map((item, index) => ({
          id: item.id || uuid(),
          order: item.order || index,
          ...item
        }))
        .sort((a, b) => a.order - b.order)
    )

    const validateResponsiveVars = (breakpoint: SwiperBreakpoint): SwiperBreakpoint => {
      const gap: number = breakpoint.gap || defaultBreakpoint.gap
      const countVisible: number = breakpoint.countVisible || defaultBreakpoint.countVisible

      return {
        minWidth: breakpoint.minWidth || defaultBreakpoint.minWidth,
        maxWidth: breakpoint.maxWidth || defaultBreakpoint.maxWidth,
        countVisible: validateCountVisible(countVisible),
        gap
      }
    }

    const validateCountVisible = (count: number) => {
      if (count > sortedItems.value.length) {
        return sortedItems.value.length
      }

      if (count < 0) {
        return 1
      }

      return count
    }

    const currentBreakpointVars = computed(() => {
      let r: SwiperBreakpoint = { ...defaultBreakpoint }

      for (const breakpoint of props.responsive) {
        const minWidth = breakpoint.minWidth || 0
        const maxWidth = breakpoint.maxWidth || null

        if (currentDeviceWidth.value >= minWidth && (maxWidth === null || currentDeviceWidth.value <= maxWidth)) {
          r = { ...breakpoint }
        }
      }

      return validateResponsiveVars(r)
    })

    watch(
      () => currentBreakpointVars.value.countVisible,
      () => {
        swipeSteps(0)
      }
    )

    const styleVars = computed(() => ({
      '--swiper-gap': `${currentBreakpointVars.value.gap}px`,
      '--swiper-count-visible': currentBreakpointVars.value.countVisible,
      '--swiper-offset': swipeOffset.value
    }))

    const slotBindings = computed(() => ({
      swipe,
      swipeSteps,
      swipeOffset: swipeOffset.value,
      items: sortedItems.value,
      currentBreakpoint: currentBreakpointVars.value
    }))

    const swipe = (updatedOffset: number) => {
      if (updatedOffset <= 0) {
        swipeOffset.value = 0

        return
      }

      if (updatedOffset >= possibleOffset.value) {
        swipeOffset.value = possibleOffset.value
        return
      }

      swipeOffset.value = updatedOffset
    }

    const swipeSteps = (count: number) => {
      const updatedSize = swipeOffset.value + count

      swipe(updatedSize)
    }

    const nodeRef = ref<HTMLDivElement | null>(null)

    const movementX = ref<number | null>(null)
    const coefficientOutOffset = 0.2

    // Common listeners
    const moveListener = (coefficient: number, newMovement: number) => {
      if (
        swipeOffset.value >= possibleOffset.value + coefficientOutOffset ||
        swipeOffset.value <= -coefficientOutOffset
      ) {
        return
      }

      swipeOffset.value = swipeOffset.value + coefficient

      movementX.value = newMovement
    }

    const endListener = (isFloorRound: boolean) => {
      movementX.value = null

      if (nodeRef.value) {
        const roundingFunc = isFloorRound ? Math.floor : Math.ceil

        const animationOffset = roundingFunc(swipeOffset.value)

        runAnimation(() => {
          const offset = props.speedSwipe * (1 / 120)

          if (swipeOffset.value === 0 || swipeOffset.value === possibleOffset.value) {
            cancelAnimation()
          }

          if (swipeOffset.value < 0) {
            swipeOffset.value = swipeOffset.value + offset > 0 ? 0 : swipeOffset.value + offset

            return
          }

          if (swipeOffset.value > possibleOffset.value) {
            swipeOffset.value =
              swipeOffset.value - offset < possibleOffset.value ? possibleOffset.value : swipeOffset.value - offset

            return
          }

          const updatedOffset = swipeOffset.value + offset * (swipeOffset.value - animationOffset > 0 ? -1 : 1)

          if (Math.abs(updatedOffset - animationOffset) <= offset) {
            swipeOffset.value = animationOffset

            cancelAnimation()
          } else {
            swipeOffset.value = updatedOffset
          }
        })
      }
    }

    // Desktop listeners
    const mouseDownListener = (event: MouseEvent) => {
      event.preventDefault()

      movementX.value = 0
      cancelAnimation()
    }

    const mouseMoveListener = (event: MouseEvent) => {
      event.preventDefault()

      if (nodeRef.value && typeof movementX.value === 'number') {
        const coefficientOffset =
          (currentBreakpointVars.value.countVisible / nodeRef.value.offsetWidth) *
          Math.max(nodeRef.value.offsetWidth / 1000, 1)

        const coefficient = coefficientOffset * (event.movementX > 0 ? -1 : 1)

        moveListener(coefficient, event.movementX)
      }
    }

    const mouseUpListener = (event: MouseEvent) => {
      event.preventDefault()

      const lastMovement = movementX.value

      if (typeof lastMovement === 'number') {
        endListener(lastMovement > 0)
      }
    }

    // Mobile listeners

    let touchStartX = 0

    const touchStart = (event: TouchEvent) => {
      const clientX = event.changedTouches[0].clientX

      touchStartX = clientX
      movementX.value = clientX

      cancelAnimation()
    }

    const touchMove = (event: TouchEvent) => {
      const currentX = event.changedTouches[0].clientX

      if (typeof movementX.value === 'number') {
        const coefficient = -(currentX - movementX.value) * 0.001

        moveListener(coefficient, currentX)
      }
    }

    const touchEnd = (event: TouchEvent) => {
      event.preventDefault()

      const clientX = event.changedTouches[0].clientX

      endListener(clientX - touchStartX > 0)

      touchStartX = 0
    }

    onMounted(() => {
      new ResizeObserver(() => {
        const width = window.innerWidth

        if (width) {
          currentDeviceWidth.value = window.innerWidth
        }
      }).observe(document.body)

      if (nodeRef.value) {
        // Desktop listeners
        nodeRef.value.addEventListener('mousedown', mouseDownListener)

        nodeRef.value.addEventListener('mousemove', mouseMoveListener)

        document.addEventListener('mouseup', mouseUpListener)

        // Mobile listeners
        nodeRef.value.addEventListener('touchstart', touchStart)

        nodeRef.value.addEventListener('touchmove', touchMove)

        nodeRef.value.addEventListener('touchend', touchEnd)
      }
    })

    onUnmounted(() => {
      // Desktop listeners
      document.removeEventListener('mouseup', mouseUpListener)

      if (nodeRef.value) {
        nodeRef.value.removeEventListener('mousedown', mouseDownListener)

        nodeRef.value.removeEventListener('mousedown', mouseMoveListener)

        // Mobile listeners
        nodeRef.value.removeEventListener('touchstart', touchStart)

        nodeRef.value.removeEventListener('touchmove', touchMove)

        nodeRef.value.removeEventListener('touchend', touchEnd)
      }
    })

    return { sortedItems, styleVars, slotBindings, swipe, nodeRef, movementX }
  }
})
