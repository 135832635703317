
import { defineComponent, onMounted, ref, useContext } from '@nuxtjs/composition-api'
import { MapContent, VueYandexMap } from '.'
import type { SchoolOrganizationModel, OrganizationModel } from '~/entities/school/model'
import type { MarkerMap } from '~/shared/interface'
import type { RegionModel } from '~/shared/model'

export default defineComponent({
  name: 'MapContainer',
  components: { MapContent, VueYandexMap },
  setup() {
    const ctx = useContext()

    const activeMarkerId = ref<MarkerMap['id']>('')
    const markersList = ref<MarkerMap[]>([])
    const schoolList = ref<SchoolOrganizationModel[]>([])

    const getSchools = (): Promise<void> => {
      return ctx.$services.schools.getSchoolsForMap().then((data) => {
        schoolList.value = data
      })
    }

    const fillMarkersList = (filterRegion?: RegionModel): void => {
      markersList.value = []
      schoolList.value.forEach((item) => {
        if (item.organization.hid) {
          if (filterRegion) {
            if (item.organization.address?.region?.id === filterRegion.id) {
              markersList.value.push(getMarker(item.organization!))
            }
          } else {
            markersList.value.push(getMarker(item.organization))
          }
        }
      })
    }

    const replaceRegionForAddress = (address: string): string => {
      const addressParts = address.split(',')

      if (addressParts.length > 1) {
        addressParts.shift()
        return addressParts.join(',').trim()
      }

      return address
    }

    const getMarker = (organization: OrganizationModel): MarkerMap => {
      return {
        id: organization.hid,
        latitude: organization.address.geo?.lat,
        longitude: organization.address.geo?.lon,
        balloon: {
          name: organization.nameShort,
          address: replaceRegionForAddress(organization.address.value),
        },
      } as MarkerMap
    }

    const onSelectRegion = (region: RegionModel | undefined): void => {
      fillMarkersList(region)
      activeMarkerId.value = ''
    }

    const onOpenBalloon = (id: string): void => {
      activeMarkerId.value = id
    }

    const onCloseBalloon = (): void => {
      activeMarkerId.value = ''
    }

    const onMarkerClick = (marker: MarkerMap): void => {
      activeMarkerId.value = marker.id
    }

    const onSelectSchool = (school: SchoolOrganizationModel): void => {
      activeMarkerId.value = activeMarkerId.value === school.organization.hid ? '' : school.organization.hid
    }

    onMounted(() => {
      getSchools().then(() => fillMarkersList())
    })

    return {
      schoolList,
      markersList,
      activeMarkerId,
      onSelectRegion,
      onOpenBalloon,
      onCloseBalloon,
      onSelectSchool,
      onMarkerClick,
    }
  },
})
