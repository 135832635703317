
import { computed, defineComponent, PropType, useContext, toRef } from '@nuxtjs/composition-api'
import { ArticleChip } from '~/widgets/articles/view/article'
import type { ArticleModel } from '~/entities/article/model'
import { CounterModel } from '~/shared/model'
import { toDateFormat } from '~/shared/utils/date'
import { isString } from '~/shared/utils/guards'
import type { JSONContent } from '~/interfaces/editor'
import { FavoriteEntityEnum } from '~/entities/favorites/enums'
import { StatisticCountersEnum } from '~/shared/enums'
import { useAuthorization, useDevices, useErrors, useGlobalState } from '~/composables'

export default defineComponent({
  components: { ArticleChip },
  props: {
    article: {
      type: Object as PropType<ArticleModel>,
      required: true,
    },
  },
  setup(props) {
    const ctx = useContext()
    const { showAuthorizationRedirect } = useAuthorization()
    const { isMobile } = useDevices()
    const { displayError } = useErrors()
    const { statisticState } = useGlobalState()

    const articleModel = toRef(props, 'article')

    const likes = computed(() => props.article.rating.counters.like + 2)

    const comments = computed(() => props.article.comments.pagination?.total || 0)

    const publishedDate = computed(() => (props.article.publishedAt ? toDateFormat(props.article.publishedAt) : ''))

    const readingTime = computed(() => {
      const length = props.article.content ? getContentLength(props.article.content) : 0
      const time = Math.max(Math.round(length / 500), 1)
      return `${time > 5 ? '> 5' : time} мин`
    })

    const views = computed(() => props.article.counters.find((i) => CounterModel.checkView(i))?.counter ?? '1')

    const getContentLength = (content: JSONContent): number => {
      if (isString(content.text)) {
        return content.text.length
      }

      if (Array.isArray(content.content)) {
        return content.content.reduce((initial, i) => {
          return initial + getContentLength(i)
        }, 0 as number)
      }

      return 0
    }

    const changeFavorite = () => {
      if (!ctx.$tstore.user.authorizedUser) {
        showAuthorizationRedirect()
        return
      }

      if (articleModel.value.isFavorited) {
        displayError(
          ctx.$services.favorites.unFavorite(FavoriteEntityEnum.article, props.article.id).then(() => {
            articleModel.value.isFavorited = false
            statisticState.decrement(StatisticCountersEnum.favorites)
            if (!isMobile.value) {
              ctx.$tstore.notifications.pushInfo('Статья удалена из избранного')
            }
          })
        )
      } else {
        displayError(
          ctx.$services.favorites.favorite(FavoriteEntityEnum.article, props.article.id).then(() => {
            articleModel.value.isFavorited = true
            statisticState.increment(StatisticCountersEnum.favorites)
            if (!isMobile.value) {
              ctx.$tstore.notifications.pushInfo('Статья добавлена в избранное')
            }
          })
        )
      }
    }

    return { likes, comments, publishedDate, readingTime, views, changeFavorite }
  },
})
