
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { Chip } from '~/shared/ui/base'
import type { ArticleModel } from '~/entities/article/model'
import type { UserModel } from '~/entities/user/model'
import { toDateFormat } from '~/shared/utils/date'
import { useDevices } from '~/composables'

export default defineComponent({
  components: { Chip },
  props: {
    article: {
      type: Object as PropType<ArticleModel>,
      required: true,
    },
  },
  setup(props) {
    const { isMobile } = useDevices()

    const previewAuthor = (author: UserModel) => `${author.profile.surName} ${author.profile.name}`

    const variablesCss = computed(() => ({
      '--bg-image-url': props.article.preview?.path ? `url(${props.article.preview.path})` : '',
    }))
    const authorData = computed(() => {
      return `${previewAuthor(props.article.author)}, ${toDateFormat(props.article.publishedAt)}`
    })

    const chips = computed(() => [
      { id: 1, icon: 'support/like-1', counts: props.article.rating.counters.like + 2 },
      { id: 3, icon: 'messages/message', counts: props.article.comments.pagination?.total ?? 0 },
    ])

    return { chips, variablesCss, authorData, isMobile }
  },
})
