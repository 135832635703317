
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import { SvoiButtonLink } from '@svoi-ui/components/button'
import { InfoBlock } from '~/widgets/banners/main-banner'

export default defineComponent({
  name: 'MainBanner',
  components: {
    SvoiButtonLink,
    InfoBlock,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
    image: {
      default: '',
      type: String,
    },
  },
  emits: ['handleClickLogin'],
  setup() {
    const ctx = useContext()

    const isAuth = computed(() => !!ctx.$tstore.user.authorizedUser)

    return { isAuth }
  },
})
