
import { defineComponent, PropType, ref, computed } from '@nuxtjs/composition-api'
import { SearchCallback, OptionsSelect } from '@svoi-ui/interfaces/select'
import { debounce } from '@svoi-ui/shared/utils/decorators'
import { SvoiSelect } from '.'

export default defineComponent({
  name: 'SvoiAsyncSelect',
  components: { SvoiSelect },
  props: {
    ...SvoiSelect.props,
    searchCallback: {
      type: Function as PropType<SearchCallback>,
      required: true
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  emits: ['input', 'blur'],
  setup(props) {
    const options = ref<OptionsSelect>([])
    const loading = ref(false)

    const onSearchChange = (query: string) => {
      if (query) {
        loading.value = true
        props
          .searchCallback(query)
          .then(data => {
            options.value = data
          })
          .finally(() => {
            loading.value = false
          })
      }
    }

    const debouncedSearchChange = computed(() =>
      props.delay > 0 ? debounce(onSearchChange, props.delay) : onSearchChange
    )

    return { options, loading, debouncedSearchChange }
  }
})
