
import { defineComponent } from '@nuxtjs/composition-api'
import { BannerRegister } from '.'
import { BannerRegister as BannerRegisterData } from '~/interfaces/banner'

export default defineComponent({
  name: 'BannerRegisterList',
  components: { BannerRegister },
  setup() {
    const items: Array<BannerRegisterData> = [
      {
        title: 'Школьник',
        description:
          'Заниматься сельским хозяйством интересно и перспективно! Узнай больше об аграрной науке и современных профессиях в этой отрасли',
        background: '#39A935',
        image: require('~/assets/images/banner/register/student.png'),
        link: '/register/student',
      },
      {
        title: 'Преподаватель',
        description:
          'Времени на обучение стало больше, а подготовки к урокам — меньше! Подключайте своих учеников к платформе и выдавайте им готовые материалы дистанционно',
        background: '#E48F00',
        image: require('~/assets/images/banner/register/teacher.png'),
        link: '/register/teacher',
      },
    ]

    return { items }
  },
})
