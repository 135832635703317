// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/fonts/Golos-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/fonts/Golos-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../assets/fonts/Golos-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../../assets/fonts/Golos-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../../assets/fonts/Golos-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../../../assets/fonts/Golos-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../../../assets/fonts/Golos-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../../../../assets/fonts/Golos-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../../../../assets/fonts/Golos-Bold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Golos\";font-display:swap;src:local(\"Golos Regular\"),local(\"Golos-Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:400;font-style:normal}@font-face{font-family:\"Golos\";font-display:swap;src:local(\"Golos Medium\"),local(\"Golos-Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");font-weight:500;font-style:normal}@font-face{font-family:\"Golos\";font-display:swap;src:local(\"Golos Bold\"),local(\"Golos-Bold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"truetype\");font-weight:700;font-style:normal}.user-card>.user[data-v-5cb4f6a5]{display:flex;flex-flow:row nowrap}.user-card[data-v-5cb4f6a5],.user-card>.user>.info[data-v-5cb4f6a5]{display:flex;flex-direction:column}*[data-v-5cb4f6a5]{box-sizing:border-box}*[data-v-5cb4f6a5],body[data-v-5cb4f6a5]{font-family:\"Golos\",sans-serif}body[data-v-5cb4f6a5]{font-size:16px;line-height:20px;background:#f5f7f9}a[data-v-5cb4f6a5],body[data-v-5cb4f6a5]{color:#101e2c}a[data-v-5cb4f6a5]{text-decoration:none;transition:all .3s ease}.page[data-v-5cb4f6a5]{flex-grow:1}.user-card[data-v-5cb4f6a5]{width:100%;height:-moz-fit-content;height:fit-content;grid-gap:24px;gap:24px}.user-card>.user[data-v-5cb4f6a5]{grid-gap:16px;gap:16px;overflow:hidden}.user-card>.user>.info[data-v-5cb4f6a5]{padding:2px 0;height:100%;align-items:center}.user-card>.user>.info>.fio[data-v-5cb4f6a5]{font-weight:500;white-space:nowrap}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
