
import { defineComponent } from '@nuxtjs/composition-api'
import MainEventsItem from './MainEventsItem.vue'
import type { Event } from '~/interfaces/events'

export default defineComponent({
  name: 'MainEvents',
  components: { MainEventsItem },
  setup() {
    const events: Event[] = [
      {
        id: 1,
        title: 'Конкурс «Я в АГРО»',
        link: 'https://unnat.fedcdo.ru/',
        image: require('~/assets/images/interesting-events/1.svg'),
        description: 'Всероссийский конкурс юных аграриев «Я в АГРО»',
        date: '25.06.2023',
        tag: 'от 7 до 18 лет',
      },
      {
        id: 2,
        title: 'Проект «Малая Тимирязевка»',
        link: 'https://mtagro.fedcdo.ru/',
        image: require('~/assets/images/interesting-events/2.svg'),
        description: 'Всероссийский сетевой проект по сортоиспытанию «Малая Тимирязевка»',
        date: '20.06.2023',
        tag: 'от 7 до 18 лет',
      },
      {
        id: 3,
        title: 'Конкурс АгроНТРИ',
        link: 'https://kids.agronti.ru/',
        image: require('~/assets/images/interesting-events/3.svg'),
        description: 'Всероссийский конкурс для школьников сельских поселений и малых городов',
        date: '19.04.2024',
        tag: '5-11 классы',
      },
      {
        id: 4,
        title: 'Школьный агростартап',
        link: 'https://xn--80ahddxdcqb6a6ioc.xn--p1ai/projects/samorealizatsiya-molodezhi/shkolnyy-agrostartap/',
        image: require('~/assets/images/interesting-events/4.svg'),
        description: 'Всероссийский проект «Школьный агростартап» реализуется при поддержке АО "Россельхозбанк',
        date: '01.06.2024',
        tag: '14-18 лет',
      },
    ]

    return { events }
  },
})
