export type OptionSelectKey = string | number
export type OptionSelect = Record<OptionSelectKey, unknown>
export type OptionsSelect = Array<OptionSelect> | OptionSelect | null

export enum SelectSizeEnum {
  l = 'l',
  m = 'm'
}

export type SelectSize = SelectSizeEnum.l | SelectSizeEnum.m

export type SearchCallback = (query: string) => Promise<OptionsSelect>
